import _ from 'lodash';

export function enumKeys(e: any) {
    return Object.keys(e).filter((item) => {
        return typeof e[item] !== "string";
    });
}

export function toCssClassName(value: any): string {
    return `${value}`.toLowerCase().replace(/_/g, "-");
}

export function toReadable(value: any): string {
    let stringValue = `${value}`;

    return stringValue[0].toUpperCase() + stringValue.substring(1).replace(/[_-]/g, " ").toLowerCase();
}

export function enumerateEnum(e) {
    return Object.keys(e)
        .filter(k => !_.isNumber(k))  // Lift out all the non-integer keys
        .map(k => e[k]); // And convert them
}


export function getStandardDeviation(array) {
    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;
    return Math.sqrt(
        array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
    );
}


export function times(n: number, c: (_?: number) => void): void {
    for (let i = 0; i < n; i++) {
        c(i);
    }
}

export function deepCopy(o: any) {
    return JSON.parse(JSON.stringify(o));
}



// Returns a sorted array.
// The `weightings` parameter is an object with a key for every unique value in `array`.
// The values for the weightings should typically be in the 0-1 range.
export function shuffleWithWeighting(array, weightings) {
    return array.sort(
        (a, b) => Math.random() - 0.5 + weightings[a.name] - weightings[b.name]
    );
}
