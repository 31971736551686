import './App.css';
import { generateRandomScheduleForPeriod, scoreForPerson, PersonScore, swapForTheBetter, transposeMatrix, generateScoresByPerson, evolve, ensurePeopleAreInTheirHomeRoom, Results } from "./Calcs2";
import React, { useEffect, useState } from 'react';
import { RoomsTable } from './RoomTable';
import { StaffTable } from './StaffTable';
import { InputSection } from './Inputs';
import { Costs, DutyType, Inputs, Person, Room } from './Model';
import Sidebar from 'react-sidebar';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'


const logo = require("./logo.svg") as string;

function App() {

    const [inputs, setInputs] = useState(loadInputs);
    const [isSidebarShown, setSidebarShown] = useState(false);
    const [results, setResults] = useState(loadResults); //createResults(generateTimetableByPeriod()));
    const [lastSavedDate, setLastSavedDate] = useState(loadLastSavedDate);
    const [notes, setNotes] = useState(loadNotes);
    const [downloadLink, setDownloadLink] = useState(null);

    function loadInputs(): Inputs {
        console.log("Loading inputs from local storage");
        const json = localStorage.getItem("inputs");

        if (json) {
            return Inputs.fromJson(json);
        } else {
            return createDefaultInputs();
        }
    }

    function loadResults(): Results {
        console.log("Loading results from local storage");
        const json = localStorage.getItem("results");

        if (json) {
            return Results.fromJson(json);
        } else {
            return createResults(generateTimetableByPeriod(inputs));
        }
    }

    function loadNotes(): string {
        return localStorage.getItem("notes") || "Try to be less of a wassock.";
    }

    function loadLastSavedDate(): Date {
        const dateString = localStorage.getItem("lastSavedDate");

        if (dateString) {
            return new Date(dateString);
        } else {
            return null;
        }
    }

    function storeInputs(inputs: Inputs) {
        const json = JSON.stringify(inputs);
        console.log("Saving inputs in local storage");
        localStorage.setItem("inputs", json);
        storeNewLastSavedDate();
    }

    function storeResults(results: Results) {
        console.log("In storeResults in App.tsx");
        setResults(results);
        const json = JSON.stringify(results);
        console.log("Saving results in local storage");
        localStorage.setItem("results", json);
        storeNewLastSavedDate();
    }

    function storeNotes(notes: string) {
        console.log(`In storeNotes in App.tsx. Notes = '${notes}'`);
        setNotes(notes);
        localStorage.setItem("notes", notes);
        storeNewLastSavedDate();
    }

    function storeNewLastSavedDate() {
        const date = new Date();
        setLastSavedDate(date);
        localStorage.setItem("lastSavedDate", date.toISOString());
    }

    function generateTimetableByPeriod(inputs: Inputs): Array<Array<Person>> {
        return new Array(inputs.numPeriods)
            .fill(null)
            .map((_, index) => generateRandomScheduleForPeriod(index, inputs));
    }

    function createResults(newTimetableByPeriod: Array<Array<Person>>): Results {
        newTimetableByPeriod = clone(newTimetableByPeriod);
        const newTimetableByRoom = transposeMatrix(newTimetableByPeriod, inputs);
        return new Results(
            newTimetableByPeriod,
            newTimetableByRoom,
            generateScoresByPerson(newTimetableByRoom, inputs));
    }

    function regenerate(inputs) {
        console.log("Regenerating based on updated inputs", inputs);
        const newTimetableByPeriod = generateTimetableByPeriod(inputs);
        storeResults(createResults(newTimetableByPeriod));
    }

    function clone(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    function swap() {
        const newTimetableByPeriod = swapForTheBetter(results.timetableByPeriod, inputs);
        if (newTimetableByPeriod) {
            storeResults(createResults(newTimetableByPeriod));
        }
    }

    function startEvolution() {
        const newTimetableByPeriod = clone(evolve(results.timetableByPeriod, 1250, inputs));
        console.log("Done evolving");
        storeResults(createResults(newTimetableByPeriod));
    }

    function tidy() {
        const newTimetableByPeriod = clone(results.timetableByPeriod);
        ensurePeopleAreInTheirHomeRoom(newTimetableByPeriod, inputs);
        storeResults(createResults(newTimetableByPeriod));
    }

    function onSwap(periodIndex, person1, person2) {
        console.log(`In App, swapping!`, periodIndex, person1, person2);
        const newTimetableByPeriod = clone(results.timetableByPeriod);
        // TODO: for some reason, after deleting people, there are some entries in here with nulls in them instead of Persons
        const roomIndex1 = newTimetableByPeriod[periodIndex].findIndex((person) => person && person.name === person1.name);
        const roomIndex2 = newTimetableByPeriod[periodIndex].findIndex((person) => person && person.name === person2.name);

        newTimetableByPeriod[periodIndex][roomIndex1] = person2;
        newTimetableByPeriod[periodIndex][roomIndex2] = person1;
        storeResults(createResults(newTimetableByPeriod));
    }

    function handleInputsChange(inputs: Inputs) {
        console.log("In handleInputsChange in App.tsx");
        storeInputs(inputs);

        // Updating the inputs will cause the 'regenerate' useEffect() below to fire asynchronously.
        setInputs(inputs);
        setSidebarShown(false);

        regenerate(inputs);
    }

    //useEffect(regenerate, [inputs]);

    function generateSidebar() {
        return (
            <>
                <button className="close-button" onClick={() => setSidebarShown(false)}>
                    <FontAwesomeIcon icon={faXmarkCircle} />
                </button>
                <InputSection inputs={inputs} onChange={handleInputsChange} />
            </>
        );
    }

    useEffect(updateDownloadDataLink, [inputs, notes, results]);

    function updateDownloadDataLink() {
        const downloadData = JSON.stringify({
            "inputs": inputs,
            "notes": notes,
            "results": results,
            "lastSavedDate": lastSavedDate
        });
        setDownloadLink(window.URL.createObjectURL(new Blob([downloadData], { type: "application/json" })));
    }

    if (results) {
        console.log("Rendering App!", results);
        return (
            <div className="App">
                <Sidebar sidebar={generateSidebar()}
                    open={isSidebarShown}
                    onSetOpen={setSidebarShown}
                    sidebarClassName="sidebar"
                    contentClassName="content"
                >
                    <Button onClick={() => regenerate(inputs)}>Regenerate</Button>
                    <Button onClick={swap}>Swap</Button>
                    <Button onClick={startEvolution}>Evolve</Button>
                    <Button onClick={tidy}>Tidy</Button>
                    <Button onClick={() => setSidebarShown(true)}>Edit Staff & Rooms</Button>
                    <span>{`Last saved: ${lastSavedDate ? lastSavedDate.toLocaleString() : 'never'}`}</span> | <span><a href={downloadLink} download="data.json">Download data</a></span>

                    <div className="notes">
                        <label>Notes</label>
                        <textarea onChange={e => storeNotes(e.target.value)} value={notes} />
                    </div>

                    <StaffTable inputs={inputs} onSwap={onSwap} results={results} />
                    <RoomsTable inputs={inputs} results={results} />
                </Sidebar>
            </div >
        );
    } else {
        return <b>Loading...</b>
    }
}


//const PERIOD_HEADINGS = ["9.20 - 11.00", "11.20 - 12.50", "1.40 - 3.40"];



function createDefaultInputs() {
    const inputs = new Inputs();
    inputs.costs = new Costs();
    inputs.costs.inRoomWeighting = 1;
    inputs.costs.firstReserveWeighting = 0.5;
    inputs.costs.secondReserveWeighting = 0.2;
    inputs.costs.fullDayInRoomCost = 1.5;
    inputs.costs.fullDayOffCost = 1.6;

    inputs.staff = [
        new Person("AC", "", "108", Person.convertAvailabilityFromArrayOfPeriods([0, 1, 3, 4, 5, 6, 7, 9, 10])),
        new Person("AH", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 6])),
        new Person("AK", "", "208", Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("AMB", "", null, Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 9, 10, 11])),
        new Person("AMC", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("AMW", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("AS", "", null, Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("AT", "", "308", Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("AW", "", "206", Person.convertAvailabilityFromArrayOfPeriods([2, 6, 7, 8])),
        new Person("AXW", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 9, 10, 11])),
        new Person("BP", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("CAS", "", "207", Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 9, 10, 11])),
        new Person("CD", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 8, 9, 10, 11])),
        new Person("CL", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("CM", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("CT", "", "309", Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("CW", "", "323", Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("Deb Huddleston", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4])),
        new Person("DO", "", "110", Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("DOR", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 11])),
        new Person("EB", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("EMC", "", "109", Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("ES", "", null, Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("GJS", "", "222", Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("HB", "", "207", Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 6, 7, 8])),
        new Person("HWN", "", null, Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("JC", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 7, 8, 11])),
        new Person("JDG", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("JER", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 9, 10, 11])),
        new Person("JG", "", null, Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 6, 7, 8])),
        new Person("JH", "", "107", Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 7, 8, 9, 10, 11])),
        new Person("JJM", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11])),
        new Person("JM", "", null, Person.convertAvailabilityFromArrayOfPeriods([])),
        new Person("JMC", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("JMF", "", "128", Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("JMQ", "", "209", Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("JMW", "", null, Person.convertAvailabilityFromArrayOfPeriods([])),
        new Person("JN", "", "305", Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("JR", "", "221", Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 9, 10, 11])),
        new Person("JS", "", null, Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 9, 10, 11])),
        new Person("JW", "", "223", Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("KO", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 7, 8])),
        new Person("KPC", "", "324", Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("KY", "", null, Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 9, 10, 11])),
        new Person("LA", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("Lawton", "", null, Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("LC", "", null, Person.convertAvailabilityFromArrayOfPeriods([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("LF", "", "321", Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 9, 10, 11])),
        new Person("LM", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("LMM", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("MH", "", "129", Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("MHH", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("MMK", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("MN", "", "307", Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 9, 10, 11])),
        new Person("NE", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("NW", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("OG", "", null, Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("PMK", "", "122", Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("PMR", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("PR", "", null, Person.convertAvailabilityFromArrayOfPeriods([9, 10, 11])),
        new Person("PY", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("RB", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("RDM", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10])),
        new Person("RMC", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("SB", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("SC", "", "322", Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("SM", "", null, Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("SMK", "", "306", Person.convertAvailabilityFromArrayOfPeriods([1, 2, 3, 4, 5, 7, 8, 9, 10, 11])),
        new Person("ST", "", null, Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("SW", "", null, Person.convertAvailabilityFromArrayOfPeriods([3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("TG", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("TJC", "", null, Person.convertAvailabilityFromArrayOfPeriods([1, 2, 6, 7, 8, 9])),
        new Person("TS", "", "225", Person.convertAvailabilityFromArrayOfPeriods([0, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("VA", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
        new Person("VB", "", null, Person.convertAvailabilityFromArrayOfPeriods([])),
        new Person("VBW", "", null, Person.convertAvailabilityFromArrayOfPeriods([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])),
    ];

    inputs.realRooms = [
        new Room("8X1", "222", 0, DutyType.ON_DUTY),
        new Room("8X2", "221", 0, DutyType.ON_DUTY),
        new Room("8X3", "223", 0, DutyType.ON_DUTY),
        new Room("8Y1", "225", 0, DutyType.ON_DUTY),
        new Room("8Y2", "325", 0, DutyType.ON_DUTY),
        new Room("8Y3", "321", 0, DutyType.ON_DUTY),
        new Room("8Z1", "322", 0, DutyType.ON_DUTY),
        new Room("8Z2", "323", 0, DutyType.ON_DUTY),
        new Room("8Z3", "324", 0, DutyType.ON_DUTY),
        new Room("9X1", "122", 1, DutyType.ON_DUTY),
        new Room("9X2", "121", 1, DutyType.ON_DUTY),
        new Room("9X3", "128", 1, DutyType.ON_DUTY),
        new Room("9Y1", "129", 1, DutyType.ON_DUTY),
        new Room("9Y2", "110", 1, DutyType.ON_DUTY),
        new Room("9Y3", "109", 1, DutyType.ON_DUTY),
        new Room("9Z1", "107", 1, DutyType.ON_DUTY),
        new Room("9Z2", "108", 1, DutyType.ON_DUTY),
        new Room("9Z3", "106", 1, DutyType.ON_DUTY),
        new Room("10X1", "206", 2, DutyType.ON_DUTY),
        new Room("10X2", "208", 2, DutyType.ON_DUTY),
        new Room("10X3", "207", 2, DutyType.ON_DUTY),
        new Room("10Y1", "306", 2, DutyType.ON_DUTY),
        new Room("10Y2", "308", 2, DutyType.ON_DUTY),
        new Room("10Y3", "307", 2, DutyType.ON_DUTY),
        new Room("10Z1", "309", 2, DutyType.ON_DUTY),
        new Room("10Z2", "209", 2, DutyType.ON_DUTY),
        new Room("10Z3", "305", 2, DutyType.ON_DUTY)
        // 1R1  == First reserve 1
        // 1R2  == First reserve 2
        // 1R...
        // 2R1  == Second reserve 1
        // 2R2  == Second reserve 2
        // 2R...
        // U1   == Unused 1
        // U2   == Unused 2
        // U...
    ];

    inputs.updateIntermediates();

    return inputs;
}

export default App;
