import { DutyType, Inputs, Person, Room } from "./Model";
import { enumKeys, getStandardDeviation, shuffleWithWeighting, times } from "./Utils";



//=============================================================================
//
//  SCORING FUNCTIONS
//
//=============================================================================

function compareDutyLoadForAllPeople() {
    // TODO
}

function nanIfNull(value) {
    return value === null ? NaN : value;
}


export class Results {
    constructor(
        timetableByPeriod: Array<Array<Person>>,
        timetableByRoom: Array<Array<Person>>,
        scoresByPerson: Map<string, PersonScore>) {
        this.timetableByPeriod = timetableByPeriod;
        this.timetableByRoom = timetableByRoom;
        this.scoresByPerson = scoresByPerson;
    }

    static fromJson(json: string): Results {
        const raw = JSON.parse(json);
        const newTimetableByPeriod = raw['timetableByPeriod'];
        newTimetableByPeriod.forEach((item) =>
            item.map((person) => Person.fromRawObject(person))
        );

        const newTimetableByRoom = raw['timetableByRoom'];
        newTimetableByRoom.forEach((item) =>
            item.map((person) => Person.fromRawObject(person))
        );

        const newScoresByPerson: Map<string, PersonScore> = new Map();
        Object.entries(raw['scoresByPerson']).forEach(entry => {
            newScoresByPerson[entry[0]] = PersonScore.fromRawObject((Object)(entry[1]));
        });

        return new Results(newTimetableByPeriod, newTimetableByRoom, newScoresByPerson);
    }

    readonly timetableByPeriod: Array<Array<Person>>;
    readonly timetableByRoom: Array<Array<Person>>;
    readonly scoresByPerson: Map<string, PersonScore>;
}


export class PersonScore {
    person: Person;
    score: number = 0;
    numPeriodsOnDuty: number = 0;
    numPeriodsFirstReserve: number = 0;
    numPeriodsSecondReserve: number = 0;
    numDaysInSchoolAndOffDuty: number = 0;
    numDaysOnDutyAllDay: number = 0;
    fairnessByDutyType: Map<DutyType, number> = enumKeys(DutyType).reduce(
        (result, item) => {
            result[item] = 1;
            return result;
        },
        new Map<DutyType, number>()
    );
    averageFairness: number = NaN;
    summaryScore: number = NaN;

    static blank(): PersonScore {
        return new PersonScore();
    }

    static fromRawObject(raw: object): PersonScore {
        const newPersonScore = new PersonScore();
        Object.assign(newPersonScore, raw);

        newPersonScore.averageFairness = nanIfNull(newPersonScore.averageFairness);
        newPersonScore.summaryScore = nanIfNull(newPersonScore.summaryScore);

        if (newPersonScore.person) {
            newPersonScore.person = Person.fromRawObject(newPersonScore.person)
        }
        return newPersonScore;
    }
}

/*
Returns a score between 0 and 1 for the given person object based on how good or poor their allocation is.
A negative score just means that they've broken rules altogether.
Things that will make their allocation better:
      They aren't on full duty 24/7
         They're on duty at least part of every day
         They're not doing way more than anyone else
      
*/
export function scoreForPerson(person: Person, generatedTimetableByRooms: Array<Array<Person>>, inputs: Inputs): PersonScore {
    //console.log("Generating score for person", person);
    person = Person.fromRawObject(person);
    const result: PersonScore = new PersonScore();

    // We're going to go through all of the 'results' and figure out, for this person, how good a score this is.

    // First, for each of the 'duty' types, how many periods are they down for?
    // Go through the results and find out where they are in each period.  Values will be one of the 'ON_DUTY', etc.
    const personDutiesPerPeriod: Array<DutyType> = new Array(inputs.numPeriods).fill(
        DutyType.OFF_DUTY
    );
    const numPeriodsByDutyType: Array<number> = new Array(4).fill(0);

    generatedTimetableByRooms.map((roomPeriods, roomIndex) => {
        let room = inputs.intermediates.rooms[roomIndex];
        roomPeriods.map((periodPerson, periodIndex) => {
            if (periodPerson !== null && room.dutyType !== DutyType.OFF_DUTY) {
                if (periodPerson.name === person.name) {
                    // Ok, so this person is in roomName during periodIndex.
                    const dutyType = room.dutyType;
                    personDutiesPerPeriod[periodIndex] = dutyType;
                    numPeriodsByDutyType[dutyType]++;

                    switch (dutyType) {
                        case DutyType.ON_DUTY:
                            result.numPeriodsOnDuty++;
                            break;

                        case DutyType.FIRST_RESERVE:
                            result.numPeriodsFirstReserve++;
                            break;

                        case DutyType.SECOND_RESERVE:
                            result.numPeriodsSecondReserve++;
                            break;

                        default:
                            break;
                    }
                }
            }
        });
    });

    //console.log(`FYI, numDays is ${inputs.numDays}, numPeriods is ${inputs.numPeriods}, and numPeriodsPerDay is ${inputs.numPeriodsPerDay}`);

    for (let day = 0; day < inputs.numDays; day++) {
        // For a given day: does the person have any periods where they're in the school and doing something?
        let hasPeriodsOnDutyOrReserveToday = false;
        let isOutOfSchoolAllDay = true;
        let isOnDutyAllDay = true;

        for (let dayPeriod = 0; dayPeriod < inputs.numPeriodsPerDay; dayPeriod++) {
            const periodIndex = day * inputs.numPeriodsPerDay + dayPeriod;
            const duty = personDutiesPerPeriod[periodIndex];
            const isOutOfSchool = !person.hasAvailability(periodIndex);

            if (!isOutOfSchool) {
                isOutOfSchoolAllDay = false;

                if (duty !== DutyType.OFF_DUTY) {
                    hasPeriodsOnDutyOrReserveToday = true;
                }
            }

            if (duty !== DutyType.ON_DUTY) {
                isOnDutyAllDay = false;
            }
        }

        if (!hasPeriodsOnDutyOrReserveToday && !isOutOfSchoolAllDay) {
            // This isn't great.  Basically they'll say to themselves "I'm supposed to be working periods x and y but I'm marked as off-duty so I'll stay in bed"
            result.numDaysInSchoolAndOffDuty++;
        }

        if (isOnDutyAllDay) {
            result.numDaysOnDutyAllDay++;
        }
    }

    // Now... a score?
    // On average, how many periods should each person be 'on duty'?
    // On average, how many periods should each person be '1st reserve'?
    // On average, how many periods should each person be '2nd reserve'?
    //
    //	For on-duty, there are NUM_REAL_ROOMS * inputs.numPeriods slots, and inputs.staff.length to fill them.  So the chances of a given staff member being in a slot is
    //		(NUM_<DUTY_TYPE>_ROOMS * inputs.numPeriods) / inputs.staff.length
    // If you're hardly there for most of the week, though, you should have an increased chance, so we should probably divide the whole thing by how many periods you'll be on the premeses:
    //		(NUM_<DUTY_TYPE>_ROOMS * inputs.numPeriods) / (inputs.staff.length * numPeriodsPersonIsAvailable)

    const numPeriodsThisPersonIsAvailable = person.numPeriodsAvailable();
    const applicableDutyTypes = [
        DutyType.ON_DUTY,
        DutyType.FIRST_RESERVE,
        DutyType.SECOND_RESERVE
    ];
    const numRoomsByType = [
        inputs.realRooms.length,
        inputs.numFirstReserves,
        inputs.numSecondReserves
    ];

    // Below was attempt #1 at figuring out how 'fair' it is for this person - i.e. have they been allocated on
    // almost every period (=unfair)?
    //
    //
    const roomChancesByType = numRoomsByType.map(
        (numRooms) =>
            (numRooms * inputs.numPeriods) / (inputs.staff.length * numPeriodsThisPersonIsAvailable)
    );
    /*
        Note that the above is considering reserve rooms to be just as important as real ones.
        Examples of the above:
            For someone who's in all day, you'd expect their chances of a given period to be on-duty to be:
                (25*12) / (75 * 12) = 0.333
            For someone who's in for one period all week, chances would be:
                (25*12) / (75 * 1) = 4
                This is obviously ludicrous, but it's just trying to say "this person will definitely get a room"
        
        
        So 
    */

    roomChancesByType.map((roomChance, index) => {
        const dutyType = applicableDutyTypes[index];
        result.fairnessByDutyType[dutyType] =
            numPeriodsByDutyType[dutyType] / roomChance;
    });

    // The above doesn't seem very reliable.  
    //      Someone who's in school every period and who is on the rota for 10 periods gets a score of 22
    //      Someone who's in school every period and is on the rota for only 4 preiods gets a score of 8
    //      Someone who's never in school gets a score of 0
    //      Someone who's only in school for 3 periods and is on rota for all 3 gets score of 2.1
    //      etc.
    //
    // Probably we can just calculate the fairness as:
    //      number of periods they're on this particular duty type / number of periods they're in school
    //
    // 

    applicableDutyTypes.map(dutyType => {
        result.fairnessByDutyType[dutyType] = 10 * numPeriodsByDutyType[dutyType] / numPeriodsThisPersonIsAvailable;
    });

    result.averageFairness = applicableDutyTypes.reduce((sum, dutyType) => sum + result.fairnessByDutyType[dutyType], 0) / applicableDutyTypes.length;

    /*
    This is fine, but we don't know at this point whether this is a 'good' score compared to everyone else's.


    // High fairness score implies that this person is doing too much
    // Low fairness score implies that they're not pulling their weight
    // Ideally, rather than the 'ideal' score being somehwere in the middle, we would just have an absolute score
    // that says "if it's higher then it's unfair".
    // So, if everything was ideally distributed, what would the score be?
    /*
    There are:
         A real rooms
         B R1 rooms
         C R2 rooms
         S staff
         P periods
         V total 'available' periods for all staff
        
         Number of real sessions that need to be divvied out is A*P
         Number of available staff slots is V
    
    If all the staff were in school all the time, then you'd expect each person to do 
        (A*P)/S
    periods.

    In our case, A = 25, P = 12, S = 75
    So each person should be doing around 4 on-duty periods.
    
    Some staff are only in for a few periods, and some are not in at all, though.
        The ones that aren't in at all shouldn't take part in this.
        For the ones that are, there's no point in pretending that they can manage more periods on-duty than they will be in-school.
        So, instead of (A*P)/S, we need it to be:
            We have this many things (A*P) and this many places for them to go (V)
            So the 




    */




    result.summaryScore = result.averageFairness + 1.5 * result.numDaysOnDutyAllDay - 1.5 * result.numDaysInSchoolAndOffDuty;




    return result;
}


//=============================================================================
//
//  GENERATION FUNCTIONS
//
//=============================================================================

/*
For a given period, we're going to take all of the staff that aren't at home, split them up into 4 groups (on duty, 1reserve, 2reserve, off duty).

Return value will be an array of people objects, with one entry for each 'room' (where there are additional rooms for e.g. '1R1')
An array value will be 'null' if there are no people available to do that slot.
*/
export function generateRandomScheduleForPeriod(periodIndex: number, inputs: Inputs): Array<Person> {
    let availableStaff: Array<Person | null> = inputs.staff.filter(
        (person) => Person.fromRawObject(person).hasAvailability(periodIndex)
    );
    let unavailableStaff: Array<Person> = inputs.staff.filter((person) =>
        !Person.fromRawObject(person).hasAvailability(periodIndex)
    );

    // Make it more likely that staff with high unavailability will not be put in the off-duty section
    // 		the way to do this is probably to shuffle, then sort by the number of 'unavailable' periods that person has,
    //		although we need to be careful to add some randomisation to it too, otherwise the teachers with 0 'unavailable' periods
    // 		will _always_ end up in the 'off duty' bit.

    const AVAILABILITY_WEIGHTING = 0.5;
    const weightings: Map<string, number> = availableStaff.reduce(
        (result, person) => {
            // At this stage we won't have any nulls in available staff
            result[person!.name] =
                (AVAILABILITY_WEIGHTING * Person.fromRawObject(person!).numPeriodsAvailable()) / inputs.numPeriods;
            return result;
        },
        new Map()
    );

    availableStaff = shuffleWithWeighting(availableStaff, weightings);

    if (availableStaff.length < inputs.realRooms.length) {
        throw (new Error(
            "Not enough staff to populate the on-duty rota for period " +
            periodIndex +
            "!"
        ));
    }


    // This is the main result aray
    const peopleByRoom: Array<Person> = new Array(inputs.intermediates.rooms.length).fill(null);

    // Start by taking any staff in the first NUM_REAL_ROOMS and allocating them to their preferred room
    for (let i = 0; i < inputs.realRooms.length; i++) {
        const person: Person = availableStaff[i]!;
        if (person.roomName) {
            // We're assuming that person.room will be null if that person does own a room but the room isn't used for exams
            const roomIndex: number = inputs.intermediates.rooms.findIndex(room => room.name === person.roomName);
            peopleByRoom[roomIndex] = person;
            availableStaff[i] = null; // So we don't use this person again
        }
    }

    availableStaff = availableStaff.filter((person) => person !== null);

    // Now go back through the entire list and allocate them
    for (let roomIndex = 0; roomIndex < inputs.intermediates.rooms.length; roomIndex++) {
        if (peopleByRoom[roomIndex] === null) {
            if (availableStaff.length > 0 && availableStaff[0] !== null) {
                peopleByRoom[roomIndex] = availableStaff.shift()!;
            } else {
                // Ok, hopefully we're just filling out the 'leftovers' pseudo-rooms at this point, so it's ok to use people from the 'unavailableStaff' list
                if (inputs.intermediates.rooms[roomIndex].dutyType === DutyType.OFF_DUTY) {
                    if (unavailableStaff.length > 0) {
                        peopleByRoom[roomIndex] = unavailableStaff.shift()!;
                    } else {
                        // Pretty sure it's already null anyway
                        //peopleByRoom[roomIndex] = null;
                    }
                } else {
                    console.log(
                        `Warning: not enough people to fill room ${inputs.intermediates.rooms[roomIndex]} in period ${periodIndex}`
                    );
                    // Already null, I think
                    //peopleByRoom[roomIndex] = null;
                }
            }
        }
    }

    return peopleByRoom;
}






export function transposeMatrix(original: Array<Array<Person>>, inputs: Inputs): Array<Array<Person>> {
    return new Array(inputs.intermediates.rooms.length)
        .fill(null)
        .map((_, roomIndex) => {
            if (!inputs) {
                console.log("No inputs!");
            }
            return new Array(inputs.numPeriods)
                .fill(null)
                .map(
                    (_, periodIndex) => {
                        if (original && original[periodIndex]) {
                            return original[periodIndex][roomIndex]
                        } else {
                            console.log("Oh no");
                            return null;
                        }
                    }
                );
        });
}

export function generateScoresByPerson(timetableByRoom: Array<Array<Person>>, inputs: Inputs): Map<string, PersonScore> {
    return inputs.staff.reduce(
        (map, person) => (
            (map[person.name] = scoreForPerson(person, timetableByRoom, inputs)),
            map
        ),
        new Map()
    );
}


// Finds the 'worst' score and the 'best' score and tries to swap around some periods.
// If there aren't any swappable ones then we'll find the next-best score and try again.
export function swapForTheBetter(oldTimetableByPeriod: Array<Array<Person>>, inputs: Inputs): Array<Array<Person>> {
    const oldTimetableByRoom = transposeMatrix(oldTimetableByPeriod, inputs);
    const oldScoresByPerson: Map<string, PersonScore> = generateScoresByPerson(oldTimetableByRoom, inputs);

    // It'd be handy to have the results in a format of:
    const oldPeriodsByPerson: Map<Person, Array<Room>> = new Map();

    inputs.staff.forEach(person => oldPeriodsByPerson[person.name] = new Array(inputs.numPeriods).fill(null));  // Is the null gonna barf?

    oldTimetableByPeriod.forEach((listOfRooms: Array<Person>, periodIndex) => {
        listOfRooms.forEach((person: Person, roomIndex: number) => {
            if (person) {
                const room: Room = inputs.intermediates.rooms[roomIndex];
                oldPeriodsByPerson[person.name][periodIndex] = room;
            }
        })
    });

    const peopleByScore: Array<Person> = inputs.staff
        .filter(person => !isNaN(oldScoresByPerson[person.name].averageFairness))
        .sort((a: Person, b: Person) => {
            console.log(`Comparing ${oldScoresByPerson[a.name].averageFairness} (${a.name} vs ${oldScoresByPerson[b.name].averageFairness} (${b.name}...`);
            return oldScoresByPerson[a.name].averageFairness - oldScoresByPerson[b.name].averageFairness;
        });

    console.log(`The lowest score is ${peopleByScore[0].name} (${oldScoresByPerson[peopleByScore[0].name].averageFairness}) and the highest is ${peopleByScore[peopleByScore.length - 1].name} (${oldScoresByPerson[peopleByScore[peopleByScore.length - 1].name].averageFairness})`);
    // NOW, can we find a period when the 'high' person is working but 'low' person is not?  And then swap 'em'?

    const highPerson = peopleByScore[peopleByScore.length - 1];

    for (let highPersonIndex = peopleByScore.length - 1; highPersonIndex > 1; highPersonIndex--) {
        for (let lowPersonIndex = 0; lowPersonIndex < peopleByScore.length - 2; lowPersonIndex++) {
            const lowPerson = Person.fromRawObject(peopleByScore[lowPersonIndex]);

            // Rather than always tweaking the first few periods, we'll start at a random period.
            const periodAddition = Math.floor(Math.random() * inputs.numPeriods);
            for (let i = 0; i < inputs.numPeriods; i++) {

                const periodIndex = (i + periodAddition) % inputs.numPeriods;
                const highRoom: Room = oldPeriodsByPerson[highPerson.name][periodIndex];
                const lowRoom: Room = oldPeriodsByPerson[lowPerson.name][periodIndex];
                const isLowPersonOutOfSchool = !lowPerson.hasAvailability(periodIndex);

                if ((highRoom !== null && highRoom.dutyType !== DutyType.OFF_DUTY) &&
                    (lowRoom === null || lowRoom.dutyType === DutyType.OFF_DUTY) && !isLowPersonOutOfSchool) {
                    // Ok so we can consider swapping this period
                    // TODO - when we swap, try to make sure we're not leaving someone with an empty day
                    console.log(`Ok, consider swapping period ${periodIndex} for ${highPerson.name} and ${lowPerson.name}`);
                    //oldTimetableByPeriod[periodIndex][
                    const roomIndex = oldTimetableByPeriod[periodIndex].findIndex(person => person.name === highPerson.name);
                    oldTimetableByPeriod[periodIndex][roomIndex] = lowPerson;

                    return oldTimetableByPeriod;
                }
            }
            console.log(`No good candidates found for ${lowPerson.name} so trying the next-lowest.`);
        }
    }

    console.log("No good candidates found");
    return null;
}


export function evolve(oldTimetableByPeriod: Array<Array<Person>>, maxIterations: number, inputs: Inputs): Array<Array<Person>> {

    let bestTimetable = oldTimetableByPeriod;
    let lowestScore = getOverallScore(bestTimetable, inputs);

    while (maxIterations > 0) {

        const candidateTimetable = swapRandomPeriod(bestTimetable, inputs);
        let candidateScore = getOverallScore(candidateTimetable, inputs);

        if (candidateScore < lowestScore) {
            console.log(`Found a better timetable with score ${candidateScore} vs ${lowestScore}`);
            bestTimetable = candidateTimetable;
            lowestScore = candidateScore;
        }

        maxIterations--;
    }

    return bestTimetable;
}


function getOverallScore(timetableByPeriod: Array<Array<Person>>, inputs: Inputs): number {
    const timetableByRoom = transposeMatrix(timetableByPeriod, inputs);
    const newScoresByPerson = generateScoresByPerson(timetableByRoom, inputs);
    const individualSummaryScores = Object.values(newScoresByPerson)
        .map((personScore: PersonScore) => personScore.summaryScore)
        .filter(score => !isNaN(score));
    return getStandardDeviation(individualSummaryScores);
}

// Returns a copy of the timetable with two rooms swapped in a random period.
// The new timetable may be 'better' or it may be 'worse'.
function swapRandomPeriod(oldTimetableByPeriod: Array<Array<Person>>, inputs: Inputs): Array<Array<Person>> {
    const newTimetableByPeriod = oldTimetableByPeriod.map(roomList => {
        return roomList.map(person => person);
    });

    // Pick a random period
    const periodIndex = Math.floor(Math.random() * inputs.numPeriods);

    // Now pick a random room to start with
    // We'll want to try a couple to make sure we don't end up trying to re-duty a person
    // who's out of the office on that period
    const numRooms = inputs.intermediates.rooms.length;
    const roomAStartingIndex = Math.floor(Math.random() * numRooms);
    let roomAIndex = null;


    for (let i = 0; i < numRooms; i++) {
        const roomIndex = (roomAStartingIndex + i) % numRooms;
        const person = Person.fromRawObject(newTimetableByPeriod[periodIndex][roomIndex]);

        if (person !== null &&
            person.hasAvailability(periodIndex)) {
            roomAIndex = roomIndex;
            break;
        }
    }

    if (roomAIndex === null) {
        // Highly unlikely to happen, unless we've picked a period where EVERYONE is out-of-office
        console.log(`The entire faculty is off on period ${periodIndex}!`);
        return newTimetableByPeriod;
    }

    const roomADutyType = inputs.intermediates.rooms[roomAIndex].dutyType;

    // Now we try to find another room to swap with
    const roomBStartingIndex = Math.floor(Math.random() * numRooms);
    let roomBIndex: number | null = null;

    for (let i = 0; i < numRooms; i++) {
        const roomIndex = (roomBStartingIndex + i) % numRooms;
        const person = Person.fromRawObject(newTimetableByPeriod[periodIndex][roomIndex]);

        if (person !== null &&
            person.hasAvailability(periodIndex) &&
            roomIndex !== roomAIndex &&
            inputs.intermediates.rooms[roomIndex].dutyType !== inputs.intermediates.rooms[roomAIndex].dutyType) {
            roomBIndex = roomIndex;
            break;
        }
    }

    if (roomBIndex === null) {
        // Highly unlikely to happen, unless we've picked a period where all-but-one of the people are out-of-office
        console.log(`The entire faculty (but one) is off on period ${periodIndex}`);
    }

    //console.log(`Swapping the people at rooms ${roomAIndex} and ${roomBIndex} in period ${periodIndex}`);
    newTimetableByPeriod[periodIndex][roomAIndex] = oldTimetableByPeriod[periodIndex][roomBIndex];
    newTimetableByPeriod[periodIndex][roomBIndex] = oldTimetableByPeriod[periodIndex][roomAIndex];;

    return newTimetableByPeriod;
}

function reduceNumHomeDays() {
    // So we want to find a person+day combination where the person has at least one period when they're in school
    // and all of the in-school periods are off-duty.
    // Then we want to find someone to swap with:
    //      This person will have multiple periods in that day when they're in school and on-duty.
    //      At least one of those periods has to be one of the periods when the first person is in-school.
}



// Modifies the given timetable, swapping any pairs of people who are both on-duty and who 
// are not in their home rooms.
export function ensurePeopleAreInTheirHomeRoom(timetableByPeriod: Array<Array<Person>>, inputs: Inputs) {
    for (let periodIndex = 0; periodIndex < inputs.numPeriods; periodIndex++) {

        const roomIndexesOfOnDutyPeopleWithHomeRoom = timetableByPeriod[periodIndex]
            .map((person, roomIndex) => {
                if (person && person.roomName && inputs.intermediates.rooms[roomIndex].dutyType === DutyType.ON_DUTY) {
                    return roomIndex;
                } else {
                    return -1;
                }
            })
            .filter(roomIndex => roomIndex >= 0);

        roomIndexesOfOnDutyPeopleWithHomeRoom.map(roomIndex => {
            // Is this person in their own home room?
            const person = timetableByPeriod[periodIndex][roomIndex];
            const room = inputs.intermediates.rooms[roomIndex];

            if (person.roomName !== room.name) {
                // We want to swap it with the person who's currently squatting in this person's home room.
                const homeRoomIndex = inputs.intermediates.rooms.findIndex(room => room.name === person.roomName);
                const squattingPerson = timetableByPeriod[periodIndex][homeRoomIndex];
                console.log(`${squattingPerson.name} is currently squatting in ${person.name}'s home room (${person.roomName}, so the latter is relegated to ${room.name}.  Going to swap them`);
                timetableByPeriod[periodIndex][roomIndex] = squattingPerson;
                timetableByPeriod[periodIndex][homeRoomIndex] = person;
            }
        });
    }
}