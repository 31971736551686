import React, { useState } from 'react';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import { AvailabilityType, DutyType, Inputs, Person, Room } from './Model';
import { deepCopy, enumerateEnum as enumToArray, toCssClassName } from './Utils';
import * as Yup from 'yup';
import _ from 'lodash';
import { Button } from 'react-bootstrap';


type InputsChangedCallback = (newInputs: Inputs) => void;



// Returns true if the 'name' property in every object in the list is unique
function uniqueNameValuesInList(list: Array<Object>) {
    return list.length === new Set(list.map(item => item['name'])).size;
}

const validationSchema =
    Yup.object({
        realRooms: Yup.array(
            Yup.object({
                id: Yup.string().min(1),
                name: Yup.string().min(1),
                group: Yup.number(),
                dutyType: Yup.mixed<DutyType>()//.oneOf(enumToArray(DutyType))
                //.transform(currentValue => DutyType[currentValue])
            })
        ).min(1).test('test-name', 'Room name must be unique', uniqueNameValuesInList),
        staff: Yup.array(
            Yup.object({
                name: Yup.string().min(1),
                fullName: Yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr),
                roomName: Yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr)
                ,
                // unavailablility: Yup.array(Yup.string().transform((curr, orig) => {
                //     console.log("Transforming", curr, orig);
                //     return "" + orig
                // }))
            })
        )
            .test('test-name', 'Person name must be unique', uniqueNameValuesInList)
            .test("realRoomTest", "Not a real room", function (updatedStaff) {
                // console.log(`Validating that staff rooms are good`, updatedStaff, this);
                const errors = updatedStaff.map((person, index) => {
                    if (person.roomName && !this.parent.realRooms.find(room => room.name === person.roomName)) {
                        console.log(`Person ${person.name} references room '${person.roomName}' but that doesn't exist`);
                        return new Yup.ValidationError("That's not a real room", "moof", `staff.${index}.roomName`); //, person.name, person.name);
                    } else {
                        return null;
                    }
                }).filter(Boolean);

                if (errors.length === 0) {
                    return true;
                } else {
                    return new Yup.ValidationError(errors);
                }
            }),
        costs: Yup.object({
            inRoomWeighting: Yup.number(),
            firstReserveWeighting: Yup.number(),
            secondReserveWeighting: Yup.number(),
            fullDayInRoomCost: Yup.number(),
            fullDayOffCost: Yup.number(),
        }),
        numPeriodsPerDay: Yup.number().min(1).max(50),
        numDays: Yup.number().min(1).max(50)
    });



export function InputSection(props) {
    const onChange: InputsChangedCallback = props.onChange;

    const [inputs, setNewInputs] = useState(() => props.inputs);

    const handleCostsChange = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        const newInputs = deepCopy(inputs);
        newInputs.costs[fieldName] = fieldValue;
        console.log("Setting new inputs...");
        setNewInputs(newInputs);

        // Invoke the callback so our parent knows there's been an update
        onChange(newInputs);
    };

    const costFieldNamesAndLabels = [
        { "name": "inRoomWeighting", "label": "'in a room'" },
        { "name": "firstReserveWeighting", "label": "1st reserve" },
        { "name": "secondReserveWeighting", "label": "2nd reserve" },
        { "name": "fullDayInRoomCost", "label": "full day in-room" },
        { "name": "fullDayOffCost", "label": "full day at home" },
    ];

    const handleSubmit = (newValues) => {
        console.log(newValues);

        // Convert the 'form' representation of our data into an actual Inputs object
        let f = DutyType;
        const newInputs = new Inputs();
        Object.assign(newInputs, deepCopy(newValues));

        newInputs.staff.sort((a: Person, b: Person) => {
            if (!a) {
                return b ? -1 : 0;
            }

            if (!b) {
                return 1;
            }

            const aName = a.fullName || a.name;
            const bName = b.fullName || b.name;

            if (aName === bName) {
                return 0;
            } else {
                return aName < bName ? -1 : 1;
            }
        });

        newInputs.updateIntermediates();

        console.log("Result:", newInputs);

        setNewInputs(newInputs);
        onChange(newInputs);
    }

    function setAvailability(personIndex: number, periodIndex: number, person: Person, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) {
        const currentValue = person.getAvailability(periodIndex);
        let newValue;
        switch (currentValue) {
            case AvailabilityType.AVAILABLE:
                newValue = AvailabilityType.UNAVAILABLE_OTHER;
                break;
            case AvailabilityType.UNAVAILABLE_IN_HALL:
                newValue = AvailabilityType.AVAILABLE;
                break;
            case AvailabilityType.UNAVAILABLE_OTHER:
                newValue = AvailabilityType.UNAVAILABLE_IN_HALL;
                break;
        }
        console.log("Setting availability for person", personIndex, "and period", periodIndex, ". Value is", currentValue, "and new value is", newValue, "Person is", person);
        setFieldValue(`staff.${personIndex}.availability.${periodIndex}`, newValue);
    }

    function toggleAvailabilies(personIndex: number, currentValues: Inputs, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void): void {
        const person = Person.fromRawObject(currentValues.staff[personIndex]);

        let newAvailabilityType: AvailabilityType;

        if (person.numPeriodsWithAvailability(AvailabilityType.AVAILABLE) === currentValues.numPeriods) {
            newAvailabilityType = AvailabilityType.UNAVAILABLE_OTHER;
        } else if (person.numPeriodsWithAvailability(AvailabilityType.UNAVAILABLE_OTHER) === currentValues.numPeriods) {
            newAvailabilityType = AvailabilityType.UNAVAILABLE_IN_HALL;
        } else {
            newAvailabilityType = AvailabilityType.AVAILABLE;
        }

        setFieldValue(`staff.${personIndex}.availability`, new Array(currentValues.numPeriods).fill(newAvailabilityType));
    }


    function returnFalse(_: any) {
        return false;
    }

    return (
        < div className="inputs" >
            <Formik

                initialValues={props.inputs}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue }) => (
                    <Form className="input-section">
                        <div className="fields">
                            <div className="general-settings">
                                <h2>General Settings</h2>
                                <div >
                                    <label>Number of Days</label>
                                    <FieldWithValidation type="number" name="numDays" />
                                </div>
                                <div>
                                    <label>Number of Periods per Day</label>
                                    <FieldWithValidation type="number" name="numPeriodsPerDay" />
                                </div>
                                <div className="costs">
                                    {
                                        costFieldNamesAndLabels.map((field) => (
                                            <div key={"inputs-field-" + field.name}>
                                                <label>Scoring for '{field.label}'</label>
                                                <FieldWithValidation type="text" name={'costs.' + field.name} />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <FieldArray name="staff">
                                {({ insert, remove, push }) => (
                                    <div className="staff-options-section">
                                        <h2>Staff</h2>
                                        <StandardErrorMessage name="staff" />
                                        <div className="staff-table">
                                            <div className="staff-row staff-header">
                                                <label>Nickname</label>
                                                <label>Person's full name</label>
                                                <label>Home room</label>
                                                <label>Available sessions</label>
                                            </div>

                                            {values.staff.length > 0 && values.staff.map((person: Person, personIndex: number) => {
                                                person = Person.fromRawObject(person);
                                                return (
                                                    <div className="staff-row" key={personIndex}>
                                                        <label style={{ display: "none" }}>Nickname</label>
                                                        <FieldWithValidation type="text" name={`staff.${personIndex}.name`} />
                                                        <label>Person's full name</label>
                                                        <FieldWithValidation type="text" name={`staff.${personIndex}.fullName`} />
                                                        <label>Home room</label>
                                                        <FieldWithValidation type="text" name={`staff.${personIndex}.roomName`} />

                                                        {/* Should be able to just use the same 'name' on a bunch of checkboxes, but that doesn't seem to work when my
                                                            array values are all integers and not strings. */}
                                                        <label>Available periods</label>
                                                        <span className={`availability-periods`}>
                                                            <FieldArray name={`staff.${personIndex}.availability`} key={`staff.${personIndex}.availability`}>
                                                                {({ insert, remove, push, }) => (
                                                                    <>
                                                                        {
                                                                            new Array(inputs.numDays).fill(true).map((val, dayIndex) => (
                                                                                <span key={`staff.${personIndex}.availability.day_${dayIndex}`} className={`day day-${dayIndex}`}>
                                                                                    {
                                                                                        new Array(inputs.numPeriodsPerDay).fill(true).map((val, localPeriodIndex: number) => {

                                                                                            const periodIndex = (dayIndex * inputs.numPeriodsPerDay) + localPeriodIndex;
                                                                                            const availabilityType = person.getAvailability(periodIndex);
                                                                                            return (
                                                                                                <>
                                                                                                    <span
                                                                                                        key={`staff.${personIndex}.availability.period_${periodIndex}`}
                                                                                                        className={`availability-control ${toCssClassName(availabilityType)}`}
                                                                                                        onClick={e => {
                                                                                                            setAvailability(personIndex, periodIndex, person, setFieldValue);
                                                                                                        }}
                                                                                                        tabIndex={0}></span>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </span>
                                                                            ))

                                                                        }
                                                                    </>
                                                                )}
                                                            </FieldArray>
                                                        </span>
                                                        <span>
                                                            <Button onClick={() => toggleAvailabilies(personIndex, values, setFieldValue)}> toggle all</Button>
                                                        </span>
                                                        <Button type="button" onClick={() => remove(personIndex)}>X</Button>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <Button type="button" onClick={() => push(new Person('', '', null, []))}>
                                            Add Person
                                        </Button>
                                    </div>
                                )}
                            </FieldArray>

                            <FieldArray name="realRooms">
                                {({ insert, remove, push }) => (
                                    <div>
                                        <h2> Rooms </h2>
                                        <StandardErrorMessage name="realRooms" />
                                        <div className="rooms-table">
                                            <div className="room-row room-header">
                                                <label>Room ID</label>
                                                <label>Room Name</label>
                                                <label>Group</label>
                                            </div>
                                            {values.realRooms.length > 0 && values.realRooms.map((room: Room, index: number) => (
                                                <div className="room-row" key={index}>
                                                    <label style={{ display: "none" }}>Room ID</label>
                                                    <FieldWithValidation type="text" name={`realRooms.${index}.id`} />
                                                    <label style={{ display: "none" }}>Room name</label>
                                                    <FieldWithValidation type="text" name={`realRooms.${index}.name`} />
                                                    <label style={{ display: "none" }}>Group</label>
                                                    <FieldWithValidation type="number" name={`realRooms.${index}.group`} />
                                                    <Field name={`realRooms.${index}.dutyType`} type="hidden" />
                                                    <Button type="button" onClick={() => remove(index)}>X</Button>
                                                </div>
                                            ))}
                                        </div>
                                        <Button type="button" onClick={() => push(new Room('', '', 0, DutyType.ON_DUTY))}>
                                            Add Room
                                        </Button>
                                    </div>
                                )}
                            </FieldArray>
                        </div>
                        <div className="submit-panel">
                            <input type="submit" value="Save Changes"></input>
                        </div>
                    </Form>
                )}
            </Formik>
        </div >
    );
}

function FieldWithValidation({ name, type }) {
    return (
        <>
            <div className="field-with-validation">
                <Field name={name} type={type == 'textarea' ? 'text' : type} as={type == 'textarea' ? 'textarea' : 'input'} />
                <StandardErrorMessage name={name} />
            </div>
        </>
    )
}

function StandardErrorMessage({ name }) {
    return (
        <ErrorMessage name={name}>

            {msg => {
                // Sometimes our callback function gets given an array or object, especially if it's for a parent field
                // which also has children. This is usually the case where you have a parent which has children and those
                // children have error messages on them - the error message gets presented to the child as a string, but 
                // it _also_ gets presented to the parent as an array. In this case, it's safe to ignore the parent's 
                // error message(s). 
                let messageText = "";
                let isDisplayed = false;
                if (!Array.isArray(msg)) {
                    messageText = "" + msg;
                    isDisplayed = true;
                }
                let fieldName = name;

                return <span style={{ color: 'red', fontWeight: 'bold', display: isDisplayed ? 'inline-block' : 'none' }}>{messageText}</span>
            }}
        </ErrorMessage >
    )
}