import { DutyType, Person, Room } from "./Model";
import React, { useState } from 'react';
import { PersonScore } from "./Calcs2";
import { toCssClassName, toReadable } from "./Utils";




export function StaffTable({ inputs, results, onSwap }) {
    class Slot {
        personIndex: number;
        periodIndex: number;

        constructor(personIndex, periodIndex) {
            this.personIndex = personIndex;
            this.periodIndex = periodIndex;
        }
    }

    const [selectedSlot, setSelectedSlot] = useState(() => null);


    function onSlotClickInternal(personIndex, periodIndex, e) {
        console.log(`OnSlotClick in StaffTable! Person=${personIndex}, period=${periodIndex}`);

        // Ignore any clicks that are on 'unavailable' squares.
        // Could do this by checking the className, but that seems lazy.
        const person: Person = Person.fromRawObject(inputs.staff[personIndex]);
        if (!person.hasAvailability(periodIndex)) {
            console.log(`That person is marked as 'unavailable' for the selected index ${periodIndex}`);
            return;
        }

        if (selectedSlot === null) {
            setSelectedSlot(new Slot(personIndex, periodIndex));
        } else {
            if (periodIndex === selectedSlot.periodIndex) {
                // Swappy time!
                //props.timetableByPeriod
                if (onSwap) {
                    onSwap(periodIndex, inputs.staff[selectedSlot.personIndex], person);
                }
                setSelectedSlot(null);
            } else {
                setSelectedSlot(new Slot(personIndex, periodIndex));
            }
        }
    }

    const DAY_HEADINGS = new Array(inputs.numDays).fill(null).map((_, index) => "Day " + (index + 1));

    return (
        <div>
            <h2>Staff Allocations Table</h2>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Home Room</th>
                        {DAY_HEADINGS.map((name, dayIndex) => (
                            <th key={"day" + dayIndex} colSpan={inputs.numPeriodsPerDay}>{name}</th>
                        ))}
                        <th>Fairness</th>
                        <th>Num Days Off</th>
                        <th>Num Full Days on Duty</th>
                        <th>Summary</th>
                    </tr>
                </thead>
                <tbody>
                    {inputs.staff.map((person, personIndex) => (
                        <StaffRow
                            key={"person" + personIndex}
                            inputs={inputs}
                            person={person}
                            personIndex={personIndex}
                            personScore={results.scoresByPerson[person.name]}
                            timetableByPeriod={results.timetableByPeriod}
                            onSlotClick={(periodIndex, e) => onSlotClickInternal(personIndex, periodIndex, e)}
                            selectedPeriodIndex={(selectedSlot?.personIndex === personIndex) ? selectedSlot.periodIndex : null} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}


function StaffRow({ inputs, personIndex, person, personScore, timetableByPeriod, onSlotClick, selectedPeriodIndex }) {
    person = Person.fromRawObject(person);

    function getRoomFromResults(personName, timetableByPeriod: Array<Array<Person | null>>, periodIndex): Room | null {
        const roomIndex = timetableByPeriod[periodIndex].findIndex(person => person?.name === personName);
        return roomIndex >= 0 ? inputs.intermediates.rooms[roomIndex] : null;
    }

    function getDutyClassForResults(personName, timetableByPeriod, periodIndex): string {
        const room = getRoomFromResults(personName, timetableByPeriod, periodIndex);
        const dutyType = room?.dutyType ?? DutyType.OFF_DUTY;

        switch (dutyType) {
            case DutyType.ON_DUTY:
                return "on-duty";

            case DutyType.FIRST_RESERVE:
                return "first-reserve";

            case DutyType.SECOND_RESERVE:
                return "second-reserve";

            case DutyType.OFF_DUTY:
                return "off-duty";

            default:
                throw new Error(`Unknown duty type '${dutyType}'`);
        }
    }

    personScore = personScore || PersonScore.blank();

    return (
        <tr>
            <td title={person.fullName}>{person.name}</td>
            <td>{person.roomName}</td>
            {Array.from({ length: inputs.numPeriods }).map((_, periodIndex) => {
                const dutyClass = getDutyClassForResults(person.name, timetableByPeriod, periodIndex);
                return (
                    <td key={"person" + personIndex + "_period" + periodIndex}
                        className={`slot 
                            ${toCssClassName(person.getAvailability(periodIndex))} 
                            ${person.hasAvailability(periodIndex) ? " " : "unavailable "} 
                            ${dutyClass}
                            ${selectedPeriodIndex === periodIndex ? " selected" : ""}`}
                        onClick={(e) => onSlotClick(periodIndex, e)}
                        title={`${toReadable(person.getAvailability(periodIndex))} | ${toReadable(dutyClass)}`}
                    >
                        {getRoomFromResults(
                            person.name,
                            timetableByPeriod,
                            periodIndex
                        )?.name}
                    </td>
                )
            })}
            <td title={JSON.stringify(personScore).replace(/,/g, ",\n  ")}>
                {personScore.averageFairness.toFixed(1)}
            </td>
            <td>
                {personScore.numDaysInSchoolAndOffDuty}
            </td>
            <td>
                {personScore.numDaysOnDutyAllDay}
            </td>
            <td>
                {personScore.summaryScore.toFixed(1)}
            </td>
        </tr>
    );
}