


function getMandatoryValue(json: object, key: string) {
    if (json === null || json === undefined) {
        throw ("Json shouldn't be null!");
    }
    if (!(key in json)) {
        throw (`Missing property '${key}' in object: ${JSON.stringify(json)}`)
    }
    return json[key];
}

export enum AvailabilityType {
    AVAILABLE = "AVAILABLE",
    UNAVAILABLE_IN_HALL = "UNAVAILABLE_IN_HALL",
    UNAVAILABLE_OTHER = "UNAVAILABLE_OTHER"
}

export class Person {
    name: string;
    fullName: string;
    roomName: string | null;
    private availability: Array<AvailabilityType> = [];

    constructor(name: string, fullName: string, roomName: string | null, availability: Array<AvailabilityType>) {
        this.name = name;
        this.fullName = fullName;
        this.roomName = roomName;
        this.availability = availability;
    }

    getAvailability(period: number): AvailabilityType {
        return this.availability.length > period ? this.availability[period] : AvailabilityType.UNAVAILABLE_OTHER;
    }

    hasAvailability(period: number): boolean {
        return this.availability.length > period && this.availability[period] === AvailabilityType.AVAILABLE;
    }

    numPeriodsAvailable(): number {
        return this.availability.filter(item => item === AvailabilityType.AVAILABLE).length
    }

    setAvailability(period: number, available: boolean) {
        while (this.availability.length <= period) {
            this.availability.push(AvailabilityType.UNAVAILABLE_OTHER);
        }

        this.availability[period] = available ? AvailabilityType.AVAILABLE : AvailabilityType.UNAVAILABLE_OTHER;
    }

    numPeriodsWithAvailability(availabilityType: AvailabilityType): number {
        return this.availability.filter(item => item === availabilityType).length;
    }

    static fromRawObject(json: object): Person {
        if (json === null) {
            return null;
        }

        return new Person(
            getMandatoryValue(json, "name"),
            json['fullName'],
            json['roomName'],
            Person.convertOldAvailabilityArrayIfNecessary(getMandatoryValue(json, 'availability'))
        );
    }

    static convertAvailabilityFromArrayOfPeriods(originalArray: Array<number>): Array<AvailabilityType> {
        let newArray = new Array<AvailabilityType>();

        for (let item of originalArray) {
            let period = item as number;
            while (newArray.length <= period) {
                newArray.push(AvailabilityType.UNAVAILABLE_OTHER);
            }

            newArray[period] = AvailabilityType.AVAILABLE;
        }

        return newArray;
    }

    private static convertOldAvailabilityArrayIfNecessary(originalArray: Array<unknown>): Array<AvailabilityType> {
        originalArray = originalArray.filter(item => item !== null && item !== undefined)

        if (originalArray.length > 0) {
            switch (typeof originalArray[0]) {
                case 'number':
                    return Person.convertAvailabilityFromArrayOfPeriods(originalArray as Array<number>);

                case 'string':
                    let newArray = new Array<AvailabilityType>();
                    for (let item of originalArray) {
                        newArray.push(AvailabilityType[item as string]);
                    }
                    return newArray;

                default:
                    console.log("## Got other!", typeof originalArray[0], originalArray);
                    throw (`Unknown type in the availability array: ${typeof originalArray[0]}`);
            }
        }

        return [];
    }
}


export class Costs {
    inRoomWeighting: number; // 
    firstReserveWeighting: number;
    secondReserveWeighting: number;
    fullDayInRoomCost: number;
    fullDayOffCost: number;
}

export class InputsIntermediates {
    rooms: Array<Room> = new Array();
    roomsByName: Map<string, Room> = new Map();
    staffByRoomName: Map<string, Person> = new Map();
    staffByName: Map<string, Person> = new Map();
}

export class Inputs {
    realRooms: Array<Room>;
    staff: Array<Person>;
    costs: Costs;
    intermediates: InputsIntermediates;
    numPeriodsPerDay: number = 3;
    numDays: number = 4;

    numFirstReserves: number = 8;
    numSecondReserves: number = 7;

    static fromJson(json: string): Inputs {
        const inputs: Inputs = Object.assign(new Inputs(), JSON.parse(json));
        inputs.costs = Object.assign(new Costs(), inputs.costs);
        inputs.updateIntermediates();
        return inputs;
    }

    get numPeriods() {
        return this.numPeriodsPerDay * this.numDays;
    }

    get numReserves() {
        return this.numFirstReserves * this.numSecondReserves;
    }

    updateIntermediates() {
        const intermediates = new InputsIntermediates();

        const numUnusedStaffPerPeriod =
            this.staff.length - (this.realRooms.length + this.numFirstReserves + this.numSecondReserves);

        intermediates.rooms = [...this.realRooms];

        for (let i = 0; i < this.numFirstReserves; i++) {
            intermediates.rooms.push(new Room("1R" + (i + 1), "", -1, DutyType.FIRST_RESERVE));
        }
        for (let i = 0; i < this.numSecondReserves; i++) {
            intermediates.rooms.push(new Room("2R" + (i + 1), "", -2, DutyType.SECOND_RESERVE));
        }
        for (let i = 0; i < numUnusedStaffPerPeriod; i++) {
            intermediates.rooms.push(new Room("U" + (i + 1), "", -3, DutyType.OFF_DUTY));
        }

        intermediates.roomsByName = intermediates.rooms.reduce((result, room) => {
            result[room.name] = room;
            return result;
        }, new Map());

        intermediates.staffByRoomName = new Map();
        intermediates.staffByName = new Map();

        for (let person of this.staff) {
            intermediates.staffByName[person.name] = person;
            if (person.roomName) {
                intermediates.staffByRoomName[person.roomName] = person;
            }
        }
        this.intermediates = intermediates;
    }
}



export enum DutyType {
    ON_DUTY,
    FIRST_RESERVE,
    SECOND_RESERVE,
    OFF_DUTY
}
export class Room {
    id: string;
    name: string;
    group: number;
    dutyType: DutyType;

    constructor(id: string, name: string, group: number, dutyType: DutyType) {
        this.id = id;
        this.name = name;
        this.group = group;
        this.dutyType = dutyType;
    }
}
