import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.tsx';
import reportWebVitals from './reportWebVitals';
import { Costs, Inputs, InputsIntermediates, Person, Room, DutyType } from './Model';
import 'bootstrap/dist/css/bootstrap.min.css';
import DocumentMeta from "react-document-meta";




const meta = {
    title: "Exam Room Planner",
    meta: {
        charset: "utf-8",
        name: {
            keywords: "react,meta,document,html,tags"
        },
        viewport: "width=device-width, initial-scale=1.0, height=device-height"
    }
};

//const defaultInputs = createDefaultInputs(); // TODO - store changes in local storage too


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <DocumentMeta {...meta} />
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
