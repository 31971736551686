import { Inputs, Person } from "./Model";
import React, { useState } from 'react';

function RoomRow(props) {
    function isAvailable(person: Person, periodIndex: number): boolean {
        if (person === null) {
            return true;
        }
        return Person.fromRawObject(person).hasAvailability(periodIndex);
    }

    function toSafeCssClassName(s: string): string {
        return encodeURIComponent(s).toLowerCase().replace(/%/g, "_");
    }

    function toggleHighlightOnSimilarElements(mainElement: Element) {
        const HIGHLIGHT_CLASS_NAME = "highlighted";

        // For some reason Element.classList.values() doesn't work here :/
        const personClassName = Array.from(mainElement.classList.value.split(/ +/)).find(
            name => name.indexOf("person_") === 0
        );
        document.querySelectorAll("." + personClassName).forEach((el) => {
            if (props.isHighlightingEnabled) {
                el.classList.toggle(HIGHLIGHT_CLASS_NAME);
            } else {
                el.classList.remove(HIGHLIGHT_CLASS_NAME);
            }
        });
    }

    function handleEnter(e) {
        console.log("Handle enter: ", e.target);
        toggleHighlightOnSimilarElements(e.target);
    }

    function handleLeave(e) {
        console.log("Handle leave: ", e);
        toggleHighlightOnSimilarElements(e.target);
    }

    function isPersonsHomeRoom(roomName: string, person: Person) {
        if (person === null) {
            return false;
        }

        return person.roomName === roomName;
    }

    if ((!props) || (!props.room) || (!props.roomTimetable)) {
        return null;
    }

    return (
        <tr className={`room-row room-row-${props.room.group}`}>
            <td>{props.room.id}</td>
            <td>{props.room.name}</td>
            {props.roomTimetable.map((person: Person, periodIndex) => (
                <td key={"room" + props.roomIndex + "_period" + periodIndex}
                    onMouseEnter={handleEnter}
                    onMouseLeave={handleLeave}
                    className={(isAvailable(person, periodIndex) ? "" : "unavailable") +
                        (" person_" + toSafeCssClassName(person?.name)) +
                        (" period_" + periodIndex) +
                        (" day_" + Math.floor(periodIndex / props.inputs.numPeriodsPerDay)) +
                        (" localPeriod_" + (periodIndex % props.inputs.numPeriodsPerDay))
                    }
                >
                    {person?.name}{" "}
                    {isPersonsHomeRoom(props.roomName, person) ? " *" : ""}
                </td>
            ))}
        </tr>
    );
}
export function RoomsTable(props) {

    const [isHighlightingEnabled, setHighlightingEnabled] = useState(true);

    function updateHighlighting(event) {
        setHighlightingEnabled(event.target.checked);
    }

    return (
        <div>
            <h2>Room Allocations Table</h2>
            <input id="enableHighlightingCheckbox" type="checkbox" checked={isHighlightingEnabled} onChange={updateHighlighting} />
            <label htmlFor="enableHighlightingCheckbox">Enable highlighting on hover</label>
            <table>
                <thead>
                    <tr>
                        <th>Room ID</th>
                        <th>Room Name</th>
                        {new Array(props.inputs.numPeriods).fill(null).map((_, index) => (
                            <th
                                key={"periodHeading" + index}
                                className={
                                    (" period_" + index) +
                                    (" day_" + Math.floor(index / props.inputs.numPeriodsPerDay)) +
                                    (" localPeriod_" + (index % props.inputs.numPeriodsPerDay))
                                }
                            >
                                P {index}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.inputs.intermediates.rooms.map((room, roomIndex) => (
                        <RoomRow
                            key={"room" + roomIndex}
                            room={room}
                            inputs={props.inputs}
                            roomIndex={roomIndex}
                            roomTimetable={props.results.timetableByRoom[roomIndex]}
                            isHighlightingEnabled={isHighlightingEnabled} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}
